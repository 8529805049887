<template>
  <custom-tooltip direction-horizontal="left"
                  direction-vertical="bottom"
                  :straight="true"
                  width="300px"
                  max-height="505px"
                  :with-padding="true"
                  :delayed-closing="true"
                  :style="{padding: 0}">
    <template v-slot:slotTrigger>

      <title-description-chart
       :classes="'small-pie'"
       :store="currentApp?.store?.key"
       width="20px"
       height="20px"
       :donut-data="{
                              title_appearance: inAsoLevel(item?.inAso, 'title_entry'),
                              short_description_appearance: inAsoLevel(item?.inAso, 'short_description_entry'),
                              description_appearance: inAsoLevel(item?.inAso, 'description_entry')}"/>
    </template>
    <template v-slot:slotContent>

      <div class="tooltip-chart-block">
        <title-description-chart width="80px"
                                 height="80px"
                                 :store="currentApp?.store?.key ?? 'APP_STORE'"
                                 :show-numbers="true"
                                 :donut-data="{title_appearance: inAsoLevel(item?.inAso, 'title_entry'),
                              short_description_appearance: inAsoLevel(item?.inAso, 'short_description_entry'),
                              description_appearance: inAsoLevel(item?.inAso, 'description_entry')}"
        />

        <div class="text-list">
          <ul>
            <li :class="[inAsoLevel(item?.inAso, 'title_entry')]">
              <span><span class="number">1.</span> Title</span>
            </li>

            <template v-if="currentApp?.store?.key === 'GOOGLE_PLAY'">
              <li :class="[inAsoLevel(item?.inAso, 'short_description_entry')]">
                <span><span class="number">2.</span> Short description</span>
              </li>

              <li :class="[inAsoLevel(item?.inAso, 'description_entry')]">
                <span><span class="number">3.</span> Full description</span>
              </li>
            </template>
            <template v-else>
              <li :class="[inAsoLevel(item?.inAso, 'short_description_entry')]">
                <span><span class="number">2.</span> Subtitle</span>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <div class="tooltip-in-aso-detailed"
           v-if="item?.inAso">
        <div class="tooltip-in-aso-detailed-inner">
          <div class="in-aso-detail-item"
               v-for="tkdItem in item.inAso">

            <div class="locale-name">{{ tkdItem.locale }}</div>
            <div class="inclusion-block">
              <div class="inclusion-item"
                   :class="tkdItem?.title_entry?.level">
                <div class="item-details"
                     v-if="tkdItem.title_entry?.missing">
                  Title (Missing: <span class="red-text">
                                    {{ tkdItem.title_entry?.missing }}
                                    </span>)
                </div>
                <span class="item-details"
                      v-else>Title
                                    </span>
              </div>

              <template v-if="currentApp?.store?.key !== 'GOOGLE_PLAY'">
                <div class="inclusion-item"
                     :class="tkdItem.short_description_entry?.level">
                  <div class="item-details"
                       v-if="tkdItem.short_description_entry?.missing">
                    Subtitle (Missing: <span class="red-text">
                                   {{ tkdItem.short_description_entry?.missing }}
                                    </span>)
                  </div>
                  <span class="item-details"
                        v-else>Subtitle
                                    </span>
                </div>
              </template>
              <template v-else>
                <div class="inclusion-item"
                     :class="tkdItem.short_description_entry?.level">
                  <div class="item-details"
                       v-if="tkdItem.short_description_entry?.missing">
                    Short Description (Missing: <span class="red-text">
                                   {{ tkdItem.short_description_entry?.missing }}
                                    </span>)
                  </div>
                  <span class="item-details"
                        v-else>Short Description
                                    </span>
                </div>

                <div class="inclusion-item"
                     :class="tkdItem.description_entry?.level">
                  <div class="item-details"
                       v-if="tkdItem.description_entry?.missing">
                    Full Description (Missing: <span class="red-text">
                                   {{ tkdItem.description_entry?.missing }}
                                    </span>)
                  </div>
                  <span class="item-details"
                        v-else>Full Description
                                    </span>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>

    </template>
  </custom-tooltip>
</template>

<script>
import {defineComponent} from 'vue';
import TitleDescriptionDonut from "@/components/UI/TitleDescriptionDonut/index.vue";

export default defineComponent({
  name: "InAsoTool",
  components: {
    'title-description-chart': TitleDescriptionDonut,
  },
  props: {
    item: {
      type: Object,
    },
    currentApp: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    inAsoLevel(array, type) {
      const fullResult = array.some(item => {
        return item?.[type]?.level === 'full';
      });

      const partlyResult = array.some(item => {
        return item?.[type]?.level === 'partial';
      });

      if (fullResult) {
        return 'green';
      } else if (!fullResult && partlyResult) {
        return 'yellow';
      } else {
        return 'grey';
      }
    },
  }
})
</script>

<style scoped lang="scss">
.tooltip-chart-block {
  padding: 24px;
  border-bottom: 1px solid #eaeff8;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .pie-chart {
    margin: 0;
  }

  .text-list {
    margin: 0 0 0 19px;
    width: calc(100% - 100px);

    li {
      font-size: 14px;
      line-height: 1.43;
      color: var(--neutral-800);
      position: relative;
      padding-left: 16px;

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #c6d0e2;
      }
    }

    li.green {
      &:before {
        background-color: #6bd790;
      }
    }

    li.yellow {
      &:before {
        background-color: #ffd664;
      }
    }

    .number {
      font-size: 14px;
      line-height: 1.43;
      color: #909bb6;
    }
  }
}

.tooltip-in-aso-detailed {
  padding: 16px 24px 24px;

  .locale-name {
    font-size: 14px;
    line-height: 1.43;
    color: #909bb6;
    margin-bottom: 8px;
  }

  .inclusion-block {

    .inclusion-item {
      font-size: 14px;
      line-height: 1.43;
      color: var(--neutral-800);
      margin-bottom: 4px;
      position: relative;
      padding-left: 16px;

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #c6d0e2;
      }

      .red-text {
        color: #f17a54;
      }
    }

    .inclusion-item:last-child {
      margin-bottom: 0;
    }

    .inclusion-item.full {
      &:before {
        background-color: #6bd790;
      }
    }

    .inclusion-item.partial {
      &:before {
        background-color: #ffd664;
      }
    }
  }

  .in-aso-detail-item {
    margin-bottom: 16px;
  }

  .in-aso-detail-item:last-child {
    margin-bottom: 0;
  }

}
</style>